import { useAuthConfig } from '@medianceDevelop/fe-common'

export const useAuth = useAuthConfig({
  URL: import.meta.env.VITE_APP_URL,
  DOMAIN: import.meta.env.VITE_APP_DOMAIN,
  DOMAIN_TYPE: import.meta.env.VITE_APP_DOMAIN_TYPE,
  AUTH_API_URL: import.meta.env.VITE_APP_AUTH_API_URL,
  AUTH_SERVICE: import.meta.env.VITE_APP_AUTH_SERVICE,
  AUTH_TYPE: import.meta.env.VITE_APP_AUTH_TYPE,
  AUTH_SITE: import.meta.env.VITE_APP_AUTH_SITE,
  AUTH_URL: import.meta.env.VITE_APP_AUTH_URL,
  /* 프론트 로그인 시 필요 */
  FRONT_URL: import.meta.env.VITE_APP_FRONT_URL,
  BIZ_CENTER_URL: import.meta.env.VITE_APP_BIZ_CENTER_URL,
  STYLE_MATE_URL: import.meta.env.VITE_APP_STYLE_MATE_URL
})
