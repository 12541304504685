import axios from 'axios'
import errorHandler from './errorHandler'
import { useAuth } from '@/plugins/auth.config'

const { refresh, refreshErrorHandle } = useAuth

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const config = {
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 120 * 1000
}

const _axios = axios.create(config)

_axios.interceptors.request.use(refresh, refreshErrorHandle)

_axios.interceptors.response.use(
  function (response) {
    return response.data
  },
  async function (error) {
    await errorHandler(error)
    return Promise.reject(error)
  }
)

export default _axios
