<template>
  <cover-loading
    v-if="mbcaCampaignStore.coverData.show"
    :text="mbcaCampaignStore.coverData.text"
  />
  <router-view />
</template>

<script setup>
import { computed, watch, nextTick } from 'vue'
import { useGlobalConfig } from '@/composables/globalConfig'
import CoverLoading from '@/components/ui/CoverLoading.vue'
import { useMbcaCampaignsStore } from '@/store/apis/mbcaCampaign'

const { store, router } = useGlobalConfig()
const mbcaCampaignStore = useMbcaCampaignsStore()

const isAuth = computed(() => store.getters['auth/isAuth'])
const isAuthCheck = (value) => {
  nextTick(() => {
    if (isAuth.value) {
      if (value === 'AuthLogin') {
        const permissions = store.getters['auth/permissions']
        const menu = ['business', 'campaign', 'influence', 'calculate', 'manage', 'homepage', 'liveCommerce']

        const findMenu = menu.find((item) => {
          return Object.keys(permissions[item].subMenu).find((iter) => permissions[item].subMenu[iter].authorization.view)
        })

        const findRoute = router.getRoutes().find((item) => {
          return item.meta?.roleKeys?.menu === findMenu
        })

        router.push({ path: findRoute.path })
      }
    } else {
      if (value !== 'AuthLogin') {
        router.push({ path: '/auth' }).then(() => {
          if (router.currentRoute.value.name !== 'AuthLogin') {
            router.push({ path: '/auth' })
          }
        })
      }
    }
  })
}

watch(() => isAuth.value, (value) => {
  if (!value) {
    isAuthCheck(router.currentRoute.value.name)
  }
})

watch(() => router.currentRoute.value.name, (value) => {
  if (value) isAuthCheck(value)
}, { immediate: true })
</script>
